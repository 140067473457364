<template>
  <v-list color="transparent">
    <slide-y-up-transition group :delay="300">
      <v-list-item key="global.reference.fixed" class="green lighten-5 mx-n6">
        <v-list-item-content class="px-6">
          <form-field-text
            v-model="sInitialValue"
            label="new.global.reference"
            @save="add"
          />
        </v-list-item-content>
        <v-list-item-action>
          <v-btn :disabled="disabled" color="green" icon text @click="add">
            <icon-add size="1.2rem" />
          </v-btn>
        </v-list-item-action>
      </v-list-item>

      <template v-for="(obReference, index) in arReferenceList">
        <v-list-item :key="`global.references.${index}`">
          <v-list-item-avatar>
            <v-avatar color="grey lighten-3">{{ index + 1 }}</v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <form-field-text
              :value="obReference.reason"
              label="reason"
              required
              @input:debounce="update($event, index)"
            />
          </v-list-item-content>

          <v-list-item-action>
            <v-btn
              :disabled="disabled"
              color="red"
              icon
              text
              @click="remove(index)"
            >
              <icon-trash size="1.2rem" />
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </slide-y-up-transition>
  </v-list>
</template>

<script lang="ts">
import { InvoiceReference } from "@planetadeleste/vue-mc-gw";
import { Component, Mixins, Prop, VModel } from "vue-property-decorator";
import { SlideYUpTransition } from "vue2-transitions";
import InvoiceMixin from "@/modules/invoices/mixins/InvoiceMixin";
import { EventBus } from "@/services/event-bus";

@Component({
  components: { SlideYUpTransition },
})
export default class ReferenceGlobalForm extends Mixins(InvoiceMixin) {
  @VModel({ type: Array, default: () => [] })
  arReferenceList!: InvoiceReference[];

  @Prop(Boolean) readonly disabled!: boolean;

  sInitialValue = "";

  get globalReferences() {
    return this.$_.filter(this.references, { is_global: true });
  }

  mounted() {
    this.$nextTick(() => {
      this.$emit("input", this.globalReferences);
    });
  }

  created() {
    EventBus.on("before.add.global.reference", this.add);
  }

  beforeDestroy() {
    EventBus.off("before.add.global.reference", this.add);
  }

  add() {
    if (this.$_.isEmpty(this.sInitialValue)) {
      return;
    }

    const obReference = new InvoiceReference({
      reason: this.sInitialValue,
      is_global: true,
    });
    this.arReferenceList.push(obReference);
    this.sInitialValue = "";
    this.emit();
  }

  update(sValue: string, index: number) {
    const obReference = this.arReferenceList[index];
    if (!obReference) {
      return;
    }
    obReference.reason = sValue;
    this.arReferenceList.splice(index, 1, obReference);
    this.emit();
  }

  remove(index: number) {
    this.arReferenceList.splice(index, 1);
    this.emit();
  }

  emit() {
    this.$emit("update", this.arReferenceList);
  }
}
</script>
